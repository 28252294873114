/* @import './font.css'; */
.main-holder {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    font-size: 13px;
    /* overflow-x: hidden; */
}

.card-background {
    background-color: white;
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-right: 15px;
}

.card-div {
    width: 25%;
}

.left-top-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    max-width: 150px;
    padding: 10px
}

.left-bottom-bg {
    width: 27%;
    min-width: 200px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 0;
}
.right-bottom-bg {
    width: 27%;
    min-width: 350px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 0;
}

.signup-bookkeeper-text {
    margin-bottom: 12px;
}

.company-size-buttons {
    justify-content: space-between;
}

.company-size-individual-button {
    width: 85px !important;
}

.invalid-link-div {
    width: 30%;
}

.invalid-link-image {
    width: 50%;
}

@media only screen and (max-width: 800px) {
    .right-bottom-bg{
        display: none
    }

    .card-background {
        width: 65%;
        margin-right: 0px;
    }

    .signup-bookkeeper-text {
        margin-bottom: 0px !important;
    }
    
    .invalid-link-image {
        width: inherit;
    }
}

@media only screen and (max-width: 1520px) {
    .signup-bookkeeper-text {
        margin-bottom: 10px;
    }

    .company-size-buttons {
        justify-content: flex-start;
        gap: 10px 10px;
    }

    .company-size-individual-button {
        width: 60px !important;
    }

    .invalid-link-div {
        width: 45%;
    }

    .card-div {
        width: 45%;
    }
}