/* SIGNIN-CARD */
.cards-main-holder::-webkit-scrollbar {
    display: none;
}

/* .signin-form-card {
    background-color: white;
    width: 25%;
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-right: 15px;
}

@media only screen and (max-width: 800px) {
    .signin-form-card {
        width: 65%;
        margin-right: 0px;
    }
} */